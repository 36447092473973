/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import '~magic.css/dist/magic.min.css';
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: AppFontThin;
  src: url("assets/fonts/Montserrat-Light.ttf"); //change url accordingly
}
@font-face {
  font-family: AppFont;
  src: url("assets/fonts/Montserrat-Regular.ttf"); //change url accordingly
}
@font-face {
  font-family: AppFontBold;
  src: url("assets/fonts/Montserrat-Bold.ttf"); //change url accordingly
}


.version{

  font-size: 8px;
  position: absolute;
  bottom: 10px;
  right:15px;
  color:rgba(255,255,255,0.5);


}

.background-image{
  --background: url(assets/img/common/fondo.png) 0 0/100% 100% no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000000;

}

.vertical-align-content > *
{
  display: flex!important;
  align-content: center!important;
  align-items: center!important;
}




.img_header{
  width: 38vw;
  margin-top:1.45vh;
  left: 50%;
  position: absolute;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 5;
  /* height: 15vh;
   display: inline-block;
   position: fixed;
   z-index: 5;
 */

}


.img_header_title_small{
  width: 40vw;
  margin-top:1.30vh;
  left: 50%;
  position: absolute;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 5;
  /* height: 15vh;
   display: inline-block;
   position: fixed;
   z-index: 5;
 */

}
.glass{


  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .3);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, .25);
  backdrop-filter: blur(5px);
  color: white;

}
.glass_frosted{


  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .6);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, .40);
  backdrop-filter: blur(5px);
  color: white;

}

.img_header_title{
  width: 60vw;
   top:1.55vh;
  left: 50%;
  position: absolute;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 5;
  /* height: 15vh;
   display: inline-block;
   position: fixed;
   z-index: 5;
 */

}
.main_content{
  position: absolute;
  top: 15vh;
  bottom: 0px;
  left: 0px;
  right: 0px;


}
.icon_left{

  color:white;
   font-size: 3.5vh;
  left: 3vw;
  top: 0.8vh;
  position: relative;
  filter: drop-shadow(0 0 10px #000000);

}
.bottom_line{

  width: 100vh;
  position: fixed;
  bottom: 0px;
  background-color: #F89933;
  height: 2.5vh;
  z-index: 5;
}
.left_selector{

  background-image: url(assets/img/common/cont_right.png)  ;
  width: 15vw;
  height: 5vh;
  margin-top: 1vh;
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 5;
}
.right_selector{

  background-image: url(assets/img/common/cont_left.png)  ;
  width: 15vw;
  height: 5vh;
  right: 0vw;
  top: 4vh;
  position: fixed;
  margin-top: 1vh;
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 5;
}
.header_back{
  position: relative;
  //z-index: 1;
  margin-top: 4vh;
  height: 10vh;
  background-image: url(assets/img/common/header_img_repeat.png)  ;
  background-position: center;
  background-repeat: repeat-x;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  //background-color: transparent;
  --background: rgb(255,255,255,1);
  z-index: 5;
}
ion-header{
  position: fixed;
  --background: rgb(255,255,255,1);

}
ion-toolbar{

  text-align: center;

}

.headerfullerBack{

  width: 100vw;
  position: fixed;
  top: 0px;
  background-color: #212F66;
  height: 4vh;
  z-index: 0;

}
.modal-wrapper{
  background: rgba(0,0,0,0.7) !important;
}

@media only screen and (max-height: 600px) {

  .icon_left{


    left: 15px;
    top: 10px;
    font-size: 20px;
  }


  .img_header_title{

  width: 60vw;
  top:-3px;

}
  .img_header_title_small{
    width: 40vw;
    top:-4px;


  }

  .main_content{

    top:80px;

  }
  .left_selector{
    width: 50px;
    height: 40px;
    left: 0vw;
    top: 15px;
    position: fixed;

  }
  .right_selector{

    width: 50px;
    height: 40px;
    right: 0vw;
    top: 15px;



  }

  .headerfullerBack{

    height: 25px;

  }
  ion-header{

    height: 80px;

  }
  .header_back{
    margin-top: 20px;
    height: 60px;

  }
  .img_header{

    width: 120px;

    top:0px;

  }

}
